/* istanbul ignore file */
import { Box, FormControl, FormHelperText, MenuItem } from '@mui/material';

import styles, { StyledSelect } from './styles';
import { theme } from 'config';
import Text from '../Text/Text';

export type SelectOption = {
  value: string;
  label: string;
};

type Props = {
  value: string;
  options: SelectOption[];
  onChange: any;
  name?: string;
  label?: string;
  width?: string;
  placeholder?: string;
  errorMessage?: string | null;
  disabled?: boolean;
};

export const Select = ({
  name,
  label,
  value,
  options,
  width = '100%',
  onChange,
  placeholder,
  errorMessage,
  disabled = false,
}: Props) => {
  return (
    <FormControl sx={{ width }}>
      {label && (
        <Box sx={{ mb: 1 }}>
          <Text type="subtitle1">{label}</Text>
        </Box>
      )}
      <StyledSelect
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty={!!placeholder}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <Text
              type="body2"
              customStyling={{ color: theme.palette.grey[400] }}
            >
              {placeholder}
            </Text>
          </MenuItem>
        )}
        {options.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {errorMessage && (
        <FormHelperText sx={styles.errorMesage}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};
