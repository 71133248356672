import { Routes as DOMRoutes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './const/app-routes';
import Menu from './views/Menu/Menu';
import QrReader from './views/QRReader/QRReader';
import FlowManager from './views/FlowManager/FlowManager';

export const Routes = () => {
  return (
    <DOMRoutes>
      <Route path={ROUTES.MENU} element={<Menu />} />
      <Route path={ROUTES.QR_SCANNER} element={<QrReader />} />
      <Route path={`${ROUTES.MENU}/:flowId`} element={<FlowManager />} />
      <Route path="*" element={<Navigate to={ROUTES.QR_SCANNER} replace />} />
    </DOMRoutes>
  );
};
