import { Typography } from '@mui/material';
import { Props } from './types';

const Text = (props: Props) => {
  const {
    children,
    type,
    forceBold,
    color = 'white',
    customStyling,
    onClick,
  } = props;

  const styles = {
    ...(customStyling ? customStyling : {}),
    fontWeight: forceBold ? 600 : undefined,
  };

  return (
    <Typography
      role={type}
      variant={type}
      color={color}
      onClick={onClick}
      sx={styles}
    >
      <>{children}</>
    </Typography>
  );
};

export default Text;
