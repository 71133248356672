import { useCallback, useEffect, useRef, useState } from 'react';

import './styles.scss';

import QrScanner from 'qr-scanner';
import { ROUTES } from 'const/app-routes';
import { useNavigate } from 'react-router-dom';
import useLocalization from 'localization';
import Text from 'components/Text/Text';
import { theme } from 'config';
import Button from '../../components/Button/Button';

const QrReader = () => {
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(false);
  const navigate = useNavigate();
  const localization = useLocalization();

  const onScanSuccess = useCallback(
    (result: QrScanner.ScanResult) => {
      if (result) {
        navigate(ROUTES.MENU, { state: { iccid: result.data } });
      }
    },
    [navigate],
  );

  const stopCamera = () => {
    if (scanner?.current) {
      scanner.current.destroy();
      scanner.current = undefined;
    }
    const video = videoEl.current;
    if (video) {
      const mediaStream = video.srcObject;
      if (mediaStream instanceof MediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach(track => {
          track.stop();
        });

        video.srcObject = null;
      }
    }
    setQrOn(false);
  };

  const startCamera = useCallback(() => {
    const onScanFail = (err: string | Error) => {
      console.log(err);
    };

    const videoRef = videoEl?.current;
    if (videoRef && !scanner.current) {
      scanner.current = new QrScanner(videoRef, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
  }, [onScanSuccess, qrBoxEl, videoEl]);

  useEffect(() => {
    if (qrOn) {
      startCamera();
    }

    window.addEventListener('blur', stopCamera);
    window.addEventListener('focus', startCamera);
    return () => {
      window.removeEventListener('blur', stopCamera);
      window.removeEventListener('focus', startCamera);
    };
  }, [onScanSuccess, qrOn, startCamera]);

  return !qrOn ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '0 32px' }}>
      <Button title={localization.qr.scanButton} onClick={() => setQrOn(true)}></Button>
    </div>
  ) : (
    <div className="qr-reader">
      <video
        poster="https://cdn.indiawealth.in/public/images/transparent-background-mini.png"
        ref={videoEl}
      ></video>
      <div ref={qrBoxEl} className="qr-box">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text
            type="subtitle1"
            customStyling={{
              backgroundColor: theme.palette.grey[100],
              color: theme.palette.grey[600],
            }}
          >
            {localization.qr.scan}
          </Text>
        </div>
        <div className="qr-frame-container"></div>
      </div>
    </div>
  );
};

export default QrReader;
