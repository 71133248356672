import { DialogActions } from '@mui/material';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import MUIDialog from '@mui/material/Dialog';
import { palette } from 'config/theme/palette';

export interface DialogProps {
  open: boolean;
  text: string;
  title?: string;
  handleClose: () => void;
}

export const TextDialog = (props: DialogProps) => {
  const { open, text, title, handleClose } = props;
  const { background } = palette;
  return (
    <MUIDialog
      PaperProps={{
        sx: { backgroundColor: background.default, padding: '10px' },
      }}
      open={open}
    >
      <Text
        type="h4"
        customStyling={{ textAlign: 'center', margin: '0 0 10px 0' }}
      >
        {title}
      </Text>
      <Text
        type="body1"
        customStyling={{ textAlign: 'center', margin: '0 0 5px 0' }}
      >
        {text}
      </Text>
      <DialogActions>
        <Button title="OK" onClick={handleClose} />
      </DialogActions>
    </MUIDialog>
  );
};
