import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadDevice,
  loadDeviceMeasurements,
  saveDeviceIccid,
} from 'data-layer/device/actions';
import { AppDispatch, RootState } from 'data-layer/store';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Text from '../../components/Text/Text';
import { boxStyles, InfoSection, dividerStyles } from './styles';
import Button from '../../components/Button/Button';
import Input from '@mui/icons-material/Input';
import Output from '@mui/icons-material/Output';
import Cached from '@mui/icons-material/Cached';
import ImportContacts from '@mui/icons-material/ImportContacts';
import useLocalization from '../../localization';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../const/app-routes';

const formatDate = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const Menu = () => {
  const navigate = useNavigate();

  const localization = useLocalization();
  const { state: locationState } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    current: { errorMessage },
    iccid,
    dosellStripState,
    measurements,
  } = useSelector((state: RootState) => state.device);

  useEffect(() => {
    const currentIccid = locationState?.iccid || iccid;
    if (currentIccid && currentIccid !== iccid) {
      dispatch(saveDeviceIccid(currentIccid));
    }
    if (currentIccid) {
      dispatch(loadDevice({ iccid: currentIccid }));
      dispatch(loadDeviceMeasurements({ iccid: currentIccid }));
    }
  }, [locationState?.iccid, iccid, dispatch]);

  const disableFunctionality = !iccid || !!errorMessage;

  const getInfoText = () => {
    if (disableFunctionality) {
      return { title: localization.menu.deviceNotFound };
    }
    switch (dosellStripState) {
      case 0:
        return { title: localization.menu.stripUnloaded };
      case 1:
        return { title: localization.menu.stripIsUnloading };
      case 2:
        return { title: localization.menu.stripIsLoading };
      case 3:
        return {
          title: localization.menu.stripLoaded,
          subtile: `${formatDate(measurements?.DosellNextDisp) || 'N/A'}`,
        };
      default:
        return;
    }
  };

  return (
    <Box sx={boxStyles}>
      <Stack spacing={2}>
        <InfoSection>
          <Text type="h3">{getInfoText()?.title}</Text>
          {getInfoText()?.subtile && (
            <Stack>
              <Divider sx={dividerStyles} />
              <Text type="h5">{localization.menu.nextDispensationAt}:</Text>
              <Text type="h3">{getInfoText()?.subtile}</Text>
            </Stack>
          )}
        </InfoSection>
        <Button
          disabled={disableFunctionality}
          startIcon={<Input />}
          title={localization.menu.loadStrip}
          variant="outlined"
          onClick={() => navigate(ROUTES.LOAD_STRIP)}
        ></Button>
        <Button
          disabled={disableFunctionality}
          startIcon={<Input />}
          title={localization.menu.joinStrip}
          variant="outlined"
          onClick={() => navigate(ROUTES.JOIN_STRIPS)}
        ></Button>
        <Button
          disabled={disableFunctionality}
          startIcon={<Output />}
          title={localization.menu.unloadStrip}
          variant="outlined"
          onClick={() => navigate(ROUTES.UNLOAD_STRIP)}
        ></Button>
        <Button
          disabled={disableFunctionality}
          startIcon={<Cached />}
          title={localization.menu.configuartion}
          variant="outlined"
          onClick={() => navigate(ROUTES.CONFIGURATION)}
        ></Button>
        <Button
          disabled={disableFunctionality}
          startIcon={<ImportContacts />}
          title={localization.menu.manual}
          variant="outlined"
          onClick={() => window.location.assign('https://dosell.se/support')}
        ></Button>
      </Stack>
    </Box>
  );
};

export default Menu;
