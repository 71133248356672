import { useState } from 'react';
import Box from '@mui/material/Box/Box';
import Text from 'components/Text/Text';
import { Select, SelectOption } from 'components/Select/Select';
import Button from 'components/Button/Button';
import { RootState } from 'data-layer/store';
import { useSelector } from 'react-redux';

type InputConfig = {
  key: string;
  label: string;
  options: SelectOption[];
};

type Props = {
  inputs: InputConfig[];
  onSubmit: (data: any) => void;
  submitButtonLabel: string;
};

const Configuration = ({ inputs, submitButtonLabel, onSubmit }: Props) => {
  const device = useSelector((state: RootState) => state.device);
  const {
    dosellSoundLevel,
    dosellDaysBeforeLastDispDateNotif,
    dosellMinBeforeUncolSachetNotif,
  } = device;
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>({
    dosellSoundLevel,
    dosellDaysBeforeLastDispDateNotif,
    dosellMinBeforeUncolSachetNotif,
  });
  const handleOnChange = (key: string, value: string) => {
    setSelectedOptions({ ...selectedOptions, [key]: value });
  };
  const handleOnSubmit = () => {
    onSubmit(selectedOptions);
  };
  return (
    <Box sx={{ flexGrow: 1, padding: 4 }}>
      {inputs.map((inputConfig: InputConfig) => (
        <Box sx={{ flexGrow: 1, padding: '0 0 20px 0' }} key={inputConfig.key}>
          <Text customStyling={{ marginBottom: '5px' }} type="subtitle1">
            {inputConfig.label}
          </Text>
          <Select
            onChange={(event: any) =>
              handleOnChange(inputConfig.key, event.target.value)
            }
            value={selectedOptions[inputConfig.key]}
            options={inputConfig.options}
          />
        </Box>
      ))}
      <Button title={submitButtonLabel} onClick={handleOnSubmit} />
    </Box>
  );
};

export default Configuration;
