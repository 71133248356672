import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Container, StyledTimePickerField } from './styles';
import { ComponentProps } from 'components/types';
import dayjs from 'dayjs';
import { renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import InputAdornment from '@mui/material/InputAdornment';
import { ClockIcon } from '@mui/x-date-pickers';
import styles from 'components/DatePicker/styles';

type Props = {
  title?: string;
  value: number | null;
  onChange: (v: number | null) => void;
} & ComponentProps;

export const TimePicker = (props: Props) => {
  const { title, onChange, value } = props;

  const convertToDateJS = (date: number | null) => {
    return date === null ? null : dayjs(date);
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledTimePickerField
          value={convertToDateJS(value)}
          ampm={false}
          label={title}
          onChange={(value) => onChange(value?.toDate().getTime() || null)}
          views={['hours', 'minutes']}
          viewRenderers={{
            hours: renderMultiSectionDigitalClockTimeView as any,
            minutes: renderMultiSectionDigitalClockTimeView as any,
          }}
          slotProps={{
            mobilePaper: {
              style: styles.mobilePaper,
            },
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment sx={styles.iconStyles} position="end">
                    <ClockIcon />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      </LocalizationProvider>
    </Container>
  );
};
