import React from 'react';
import { Box } from '@mui/material';

import { StyledButton } from './styles';
import { ComponentProps } from '../../components/types';

export type Align = 'left' | 'center' | 'right';
export type FontSize = 'body1' | 'body2';

export type OnButtonClickEventType = React.MouseEvent<HTMLButtonElement>;

type Props = {
  title: string;
  color?: 'primary' | 'success' | 'error' | 'info' | 'secondary';
  align?: Align;
  variant?: 'text' | 'contained' | 'outlined';
  children?: React.ReactNode;
  fontSize?: FontSize;
  disabled?: boolean;
  onClick: (ev: OnButtonClickEventType) => void;
  width?: 'auto' | '100%';
  startIcon?: React.ReactNode;
} & ComponentProps;

const Button = (props: Props) => {
  const {
    title,
    variant = 'contained',
    children,
    color = 'primary',
    align = 'center',
    fontSize = 'body2',
    disabled,
    onClick,
    width = '100%',
    startIcon,
  } = props;
  return (
    <StyledButton
      variant={variant}
      color={color}
      align={align}
      fontSize={fontSize}
      disabled={disabled}
      onClick={onClick}
      width={width}
      startIcon={startIcon}
    >
      <>
        {children && (
          <Box sx={{ display: 'flex', ml: 0.375, mr: 1.5 }}>{children}</Box>
        )}
        {title}
      </>
    </StyledButton>
  );
};

export default Button;
